import { HistoriqueDepensesManuellesProvider } from './context/HistoriqueDepensesManuellesContext'
import EnteteHistoriqueDepensesManuelles from './components/EnteteHistoriqueDepensesManuelles'
import ListeHistoriqueDepensesManuelles from './components/ListeHistoriqueDepensesManuelles'

const HistoriqueDepensesManuelles = () => (
	<HistoriqueDepensesManuellesProvider>
		<EnteteHistoriqueDepensesManuelles />

		<ListeHistoriqueDepensesManuelles />
	</HistoriqueDepensesManuellesProvider>
)

export default HistoriqueDepensesManuelles