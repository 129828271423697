import React, { useContext } from 'react'
import { Grid } from '@mui/material'
import { fontFamilyMonserrat } from '../../theme'
import { TabOptions } from './TabsTypes'
import { makeStyles } from 'tss-react/mui'
import styles from '../../styles'
import ItemTabBar from './ItemTabBar'
import { AppContext } from '../../containers/context/AppContext'
import { APPLICATIONS_ENUM } from '../../modules/utilisateurs/enums/filtresUtilisateursEnum'

interface TabsProps {
	tabs: TabOptions[]
}

const TabBar: React.FC<TabsProps> = ({ tabs }) => {
	const { classes } = useStyles()
	const { userInfos } = useContext(AppContext)
	return (
		<Grid container item alignItems="center" className={classes.labelTab}>
			{tabs.map((tab) =>
				(
					!tab.needAdmin || userInfos.application.includes(APPLICATIONS_ENUM.SALAIRES_GARDIENS_ADMIN)) &&
				<ItemTabBar key={`item-tab-${tab.label}`} tab={tab} />
			)
			}
		</Grid>
	)
}

const useStyles = makeStyles()(theme => ({
	...styles(theme),
	labelTab: {
		fontFamily: fontFamilyMonserrat,
		fontSize: 16,
		fontWeight: 700
	}
}))

export default TabBar