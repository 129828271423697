import { Box, Grid } from '@mui/material'
import { gecinaBlueMain, gecinaBrownMain, WHITE } from '../../../theme'
import React from 'react'
import { RetourValidationImportModel } from '../types/importDepensesManuellesModel'
import Button from '../../../components/button/Button'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import { annulerImport, sauvegarderImport } from '../api/importDepensesManuellesApi'
import liens from '../../../enum/liens'
import { useNavigate } from 'react-router-dom'

interface AcceptingProps {
	retourValidation: RetourValidationImportModel,
}

type AffichageValidationImportDepensesManuellesProps = AcceptingProps
const AffichageValidationImportDepensesManuelles: React.FC<AffichageValidationImportDepensesManuellesProps> = (
	{
		retourValidation
	}
) => {
	const navigate = useNavigate()

	return (
		<Box sx={{ mt: '50px' }}>
			<Grid container item alignItems="center">
				<Grid container item sx={{ paddingBottom: '25px' }}>
					<Grid item xs>
						<span style={{
							fontFamily: 'BlackerDisplay',
							fontSize: 22,
							fontWeight: 900,
							color: gecinaBlueMain
						}}>
							Validation du fichier
						</span>
					</Grid>
				</Grid>
				<Grid container item>
					<Grid item xs sx={{ paddingBottom: '25px' }}>
						<span style={{
							fontFamily: 'Montserrat',
							fontSize: 16,
							fontWeight: 600,
							color: gecinaBlueMain
						}}>
							Le fichier est valide. Vous pouvez l’importer en cliquant sur le bouton ci-dessous.
						</span>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				container
				item
				sx={{
					padding: '17px 12px 17px 12px',
					border: '1px solid black',
					fontFamily: 'Montserrat',
					fontSize: 18,
					fontWeight: 700,
					fontStyle: 'italic',
					color: gecinaBlueMain
				}}
			>
				<Grid container item justifyContent="space-between" sx={{ mb: '10px' }}>
					<Grid item>
						Nombres de lignes
					</Grid>
					<Grid item sx={{
						fontSize: 14,
						fontWeight: 600
					}}>
						{retourValidation.nombreLigne}
					</Grid>
				</Grid>
				<Grid container item justifyContent="space-between" sx={{ mb: '10px' }}>
					<Grid item>
						Année de reddition
					</Grid>
					<Grid item sx={{
						fontSize: 14,
						fontWeight: 600
					}}>
						{retourValidation.anneeReddition}
					</Grid>
				</Grid>
				<Grid container item justifyContent="space-between" sx={{ mb: '10px' }}>
					<Grid item>
						Type de dépense
					</Grid>
					<Grid item sx={{ fontSize: 16 }}>
						{retourValidation.typeDepenses}
					</Grid>
				</Grid>
			</Grid>
			<Grid container item justifyContent="flex-end" sx={{ mb: '20px', mt: '10px', ml: 0 }}>
				<Grid item sx={{ maxWidth: 'max-content', minWidth: 'fit-content' }}>
					<Button
						sx={{
							height: '51px',
							p: 0,
							width: '195px',
							fontWeight: 600,
							lineHeight: '18.29px'
						}}
						bgcolor={gecinaBlueMain}
						color={gecinaBrownMain}
						border={false}
						onClick={() => sauvegarderImport({ uuid: localStorage.getItem('uuid') ?? '' })
							.then(() => {
								localStorage.removeItem('uuid')
								navigate(liens.historique, { state: { rafraichir: true } })
							})}
						type={buttonTypesEnum.submit}
					>
						Valider
					</Button>
				</Grid>
				<Grid item sx={{ maxWidth: 'max-content', ml: '24px', minWidth: 'fit-content' }}>
					<Button
						sx={{
							height: '51px',
							p: 0,
							width: '195px',
							fontWeight: 600,
							lineHeight: '18.29px'
						}}
						bgcolor={WHITE}
						color={gecinaBlueMain}
						border={false}
						disabled={false}
						type={buttonTypesEnum.button}
						onClick={() => annulerImport({ uuid: localStorage.getItem('uuid') ?? '' })
							.then(() => {
								localStorage.removeItem('uuid')
								navigate(liens.historique, { state: { rafraichir: true } })
							})}
					>
						Annuler
					</Button>
				</Grid>
			</Grid>
		</Box>
	)
}

export default AffichageValidationImportDepensesManuelles