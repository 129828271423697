import liens from '../../../enum/liens'
import axios from 'axios'
import { AjoutFichierBackModel, FetchUUIDModel, ResponseValidationImport, SuppressionFichierBackModel } from '../types/importDepensesManuellesModel'
import { CommunApiPlatformModel } from '../../../types/communApiPlatformModel'
import { TraitementSGNFModel } from '../../TraitementSGNF/types/traitementSGNFModel'
import { statutFichierTraitementSGNFEnum } from '../../TraitementSGNF/enums/fichierTraitementSGNFEnum'

export const fetchUuid = (): Promise<FetchUUIDModel> =>
	axios(liens.uuid)
		.then((res) => {
			return res.data
		})

export const sendFile = (infoFichier: AjoutFichierBackModel): Promise<string> => axios.post(liens.ajoutFichier, infoFichier)
	.then((response) => response.data)

export const deleteFile = (infoFichier: SuppressionFichierBackModel): Promise<string> => axios.post(liens.suppressionFichier, infoFichier)
	.then((response) => response.data)

export const validationImport = (uuid: { uuid: string }): Promise<ResponseValidationImport> => axios.post(liens.validationImport, uuid)
	.then((response) => response.data)

export const sauvegarderImport = (uuid: { uuid: string }): Promise<ResponseValidationImport> => axios.post(liens.sauvegarderImport, uuid)
	.then((response) => response.data)

export const annulerImport = (uuid: { uuid: string }): Promise<ResponseType> => axios.post(liens.annulerImport, uuid)