import React, { useContext, useMemo } from 'react'
import { HistoriqueDepensesManuellesContext } from '../context/HistoriqueDepensesManuellesContext'
import { HeadRow } from '../../../components/table/types/tableModel'
import { getKeyFromEnumValue } from '../../../utils/utils'
import { EnteteTableauListeDepensesManuellesEnum } from '../enums/enteteTableauListeDepensesManuellesEnum'
import { Box } from '@mui/material'
import Loader from '../../../components/loader/Loader'
import CustomTable from '../../../components/table/Table'
import { remplissageLigneSelonEnteteTableau } from '../../../components/table/utils/tableUtils'
import { HistoriqueDepensesManuellesModel } from '../types/historiqueDepensesManuellesModel'
import { FILTRES_HISTORIQUE_DEP_MAN } from '../enums/filtresHistoriqueDepensesManuellesEnum'
import { getListeDepensesManuelles } from '../api/historiqueDepensesManuellesApi'

const ListeHistoriqueDepensesManuelles = () => {
	const {
		historiqueDepenssesManuelles,
		setFiltresHistoriqueDepenssesManuelles,
		chargementHistoriqueDepenssesManuelles,
		filtresHistoriqueDepenssesManuelles,
		setHistoriqueDepenssesManuelles
	} = useContext(HistoriqueDepensesManuellesContext)

	const entetesTableau: HeadRow[] = useMemo(
		() => (
			[
				{
					label: EnteteTableauListeDepensesManuellesEnum.fichier_integre,
					id: getKeyFromEnumValue<EnteteTableauListeDepensesManuellesEnum>(EnteteTableauListeDepensesManuellesEnum, EnteteTableauListeDepensesManuellesEnum.fichier_integre)
				},
				{
					label: EnteteTableauListeDepensesManuellesEnum.type,
					id: getKeyFromEnumValue<EnteteTableauListeDepensesManuellesEnum>(EnteteTableauListeDepensesManuellesEnum, EnteteTableauListeDepensesManuellesEnum.type)
				},
				{
					label: EnteteTableauListeDepensesManuellesEnum.date,
					id: getKeyFromEnumValue<EnteteTableauListeDepensesManuellesEnum>(EnteteTableauListeDepensesManuellesEnum, EnteteTableauListeDepensesManuellesEnum.date),
					render: (dateTraitement) => (
						// On splitte pour ne récupérer que la date (sans les heures)
						<>{new Date(dateTraitement).toLocaleString().split(' ')[0]}</>
					)
				}
			]
		), [historiqueDepenssesManuelles]
	)

	const auChangementDePage = (
		(nouvellePage: number) => {
			const data = {
				...filtresHistoriqueDepenssesManuelles,
				[FILTRES_HISTORIQUE_DEP_MAN.PAGE]: nouvellePage
			}

			getListeDepensesManuelles(nouvellePage, filtresHistoriqueDepenssesManuelles[FILTRES_HISTORIQUE_DEP_MAN.FICHIER_INTEGRE], filtresHistoriqueDepenssesManuelles[FILTRES_HISTORIQUE_DEP_MAN.TYPE] ?? undefined)
				.then((response) => {
					setHistoriqueDepenssesManuelles(response)
					setFiltresHistoriqueDepenssesManuelles(data)
				})
				.finally(() => window.scrollTo(0, 0))
		}
	)

	if (chargementHistoriqueDepenssesManuelles) {
		return (
			<Box height="150px">
				<Loader size={50} />
			</Box>
		)
	}

	return (
		<Box sx={{ paddingX: '172px' }}>
			<CustomTable
				id="listeHistoriqueDepensesManuelles"
				headRows={entetesTableau}
				rows={remplissageLigneSelonEnteteTableau<HistoriqueDepensesManuellesModel>(historiqueDepenssesManuelles['hydra:member'], entetesTableau)}
				total={historiqueDepenssesManuelles['hydra:totalItems']}
				borderBottom
				auCliqueSurLigne={() => {
				}}
				hasPagination
				auChangementDePage={auChangementDePage}
				page={filtresHistoriqueDepenssesManuelles[FILTRES_HISTORIQUE_DEP_MAN.PAGE] - 1}
				hasSort={false}
			/>
		</Box>
	)
}

export default ListeHistoriqueDepensesManuelles