import { FILTRES_HISTORIQUE_DEP_MAN } from '../enums/filtresHistoriqueDepensesManuellesEnum'
import { FILTRES_TRAITEMENT_SG_NF } from '../../TraitementSGNF/enums/filtresTraitementSGNFEnum'

export interface HistoriqueDepensesManuellesModel {
	'@id': string,
	fichierIntegre: string,
	type: string,
	date: Date,
}

export interface TypeDepensesManuellesModel {
	'@id': string,
	libelle: string
}

export interface FiltresHistoriqueDepensesManuellesModel {
	[FILTRES_HISTORIQUE_DEP_MAN.FICHIER_INTEGRE]: string
	[FILTRES_HISTORIQUE_DEP_MAN.TYPE]: string | null,
	[FILTRES_HISTORIQUE_DEP_MAN.PAGE]: number
}

export const INITIAL_FILTRES_HISTORIQUE_DEP_MAN: FiltresHistoriqueDepensesManuellesModel = {
	[FILTRES_HISTORIQUE_DEP_MAN.FICHIER_INTEGRE]: '',
	[FILTRES_TRAITEMENT_SG_NF.TYPE]: '',
	[FILTRES_TRAITEMENT_SG_NF.PAGE]: 1
}