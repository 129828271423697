import { CommunApiPlatformModel } from '../../../types/communApiPlatformModel'
import axios from 'axios'
import { HistoriqueDepensesManuellesModel, TypeDepensesManuellesModel } from '../types/historiqueDepensesManuellesModel'

export const getListeDepensesManuelles = (page: number, fichierIntegre?: string, type?: string): Promise<CommunApiPlatformModel<HistoriqueDepensesManuellesModel[]>> =>
	axios.get(`/api/depman_historiques?page=${page}
		${fichierIntegre ? `&fichier_integre=${fichierIntegre}` : ''}
		${type && type !== 'Tous' ? `&type=${type}` : ''}
	`)
		.then((response) => response.data)

export const getTypeDepensesManuelles = (): Promise<CommunApiPlatformModel<TypeDepensesManuellesModel[]>> =>
	axios.get('/api/depman_types_depenses')
		.then((response) => response.data)