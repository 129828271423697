import { gecinaBlueMain, gecinaBrown10, gecinaBrownMain, WHITE } from '../../../theme'
import { Box, Grid } from '@mui/material'
import React, { useState } from 'react'
import CustomFormFile from '../../../components/fields/CustomFormFile'
import { annulerImport } from '../api/importDepensesManuellesApi'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import liens from '../../../enum/liens'
import Button from '../../../components/button/Button'
import { useNavigate } from 'react-router-dom'
import ErrorComponent from '../../../components/fields/ErrorComponent'
import Loader from '../../../components/loader/Loader'

export interface CorpsImportDepensesManuellesProps {
	errorMessage: string,
	setErrorMessage: (value: (((prevState: string) => string) | string)) => void
	onSubmit: () => void,
	loadingBouton: boolean,
	label: string
}

type AcceptingProps = CorpsImportDepensesManuellesProps

const CorpsImportDepensesManuelles: React.FC<AcceptingProps> = (
	{
		errorMessage,
		setErrorMessage,
		onSubmit,
		loadingBouton,
		label
	}
) => {
	const navigate = useNavigate()


	return (
		<Box sx={{ mt: '50px' }}>
			<Grid container>
				<CustomFormFile
					id="fichierImport"
					name="fichierImport"
					label={label}
					acceptedExtensions={['csv']}
					onChange={() => {
						setErrorMessage('')
					}}
				/>
			</Grid>
			<Grid container sx={{
				paddingTop: '10px',
				backgroundColor: gecinaBrown10
			}}>
				<ErrorComponent>
					{errorMessage && errorMessage}
				</ErrorComponent>
			</Grid>
			<Grid container item justifyContent="flex-start" sx={{ mb: '20px', mt: '10px', ml: 0 }}>
				<Grid item sx={{ maxWidth: 'max-content', minWidth: 'fit-content' }}>
					{loadingBouton ?
						<Loader sx={{ width: '195px' }} />
						:
						<Button
							sx={{
								height: '51px',
								p: 0,
								width: '195px',
								fontWeight: 600,
								lineHeight: '18.29px'
							}}
							bgcolor={gecinaBlueMain}
							color={gecinaBrownMain}
							border={false}
							onClick={() => onSubmit()}
							type={buttonTypesEnum.submit}
						>
							Importer
						</Button>
					}
				</Grid>
				<Grid item sx={{ maxWidth: 'max-content', ml: '24px', minWidth: 'fit-content' }}>
					<Button
						sx={{
							height: '51px',
							p: 0,
							width: '195px',
							fontWeight: 600,
							lineHeight: '18.29px'
						}}
						bgcolor={WHITE}
						color={gecinaBlueMain}
						border={false}
						disabled={false}
						type={buttonTypesEnum.button}
						onClick={() => {
							annulerImport({ uuid: localStorage.getItem('uuid') ?? '' })
								.then(() => localStorage.removeItem('uuid'))
							navigate(liens.historique, { state: { rafraichir: true } })
						}}
					>
						Annuler
					</Button>
				</Grid>
			</Grid>
		</Box>
	)
}


export default CorpsImportDepensesManuelles