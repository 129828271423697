export interface UserModel {
	email: string,
	id: string,
	name: string,
	token: string,
	application: string
}

export const INITIAL_USER: UserModel = {
	email: '',
	application: '',
	name: '',
	id: '',
	token: ''
}