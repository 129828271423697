import EnteteImportDepensesManuelles from './components/EnteteImportDepensesManuelles'
import CorpsImportDepensesManuelles from './components/CorpsImportDepensesManuelles'
import { useEffect, useState } from 'react'
import { annulerImport, fetchUuid, validationImport } from './api/importDepensesManuellesApi'
import Loader from '../../components/loader/Loader'
import { etapeImportDepensesManuelles } from './enums/importDepensesManuellesEnum'
import AffichageErreurImportDepensesManuelles from './components/AffichageErreurImportDepensesManuelles'
import { RetourValidationImportModel } from './types/importDepensesManuellesModel'
import { Box } from '@mui/material'
import AffichageValidationImportDepensesManuelles from './components/AffichageValidationImportDepensesManuelles'

const ImportDepensesManuelles = () => {
	const [loading, setLoading] = useState<boolean>(false)
	const [loadingBouton, setLoadingBouton] = useState<boolean>(false)
	const [errorMessage, setErrorMessage] = useState<string>('')
	const [etape, setEtape] = useState<etapeImportDepensesManuelles>(etapeImportDepensesManuelles.selectionFichier)
	const [retourSubmit, setRetourSubmit] = useState<RetourValidationImportModel>({})


	const onSubmit = () => {
		setLoadingBouton(true)
		const localUuid = localStorage.getItem('uuid')
		if (!localUuid || localUuid === 'null') {
			setErrorMessage('Une erreur est survenue, essayer de recharger la page')
			setLoadingBouton(false)
		} else {
			validationImport({ uuid: localUuid })
				.then((response) => {
					setRetourSubmit(response.infoRetourValidation)
					setEtape(response.passerAEtape)
				})
				.catch((error) => {
					if (error.response && error.response.data) {
						setErrorMessage(error.response.data)
					}
				})
				.finally(() => {
					setLoadingBouton(false)
				})
		}
	}

	useEffect(() => {
		setLoading(true)
		const localUuid = localStorage.getItem('uuid')
		if (localUuid) {
			annulerImport({ uuid: localStorage.getItem('uuid') ?? '' })
				.then(() => localStorage.removeItem('uuid'))

		}
		setTimeout(() =>
				fetchUuid()
					.then((res) => {
						const localUuid = res.uuid
						localStorage.setItem('uuid', localUuid!)
						setLoading(false)
					}),
			500)
	}, [])

	return (
		<>
			<EnteteImportDepensesManuelles />


			{
				loading ?
					<Loader size={80} sx={{ height: '70%' }}></Loader>
					:
					(
						<Box sx={{ paddingX: '172px' }}>
							{
								etape === etapeImportDepensesManuelles.selectionFichier ?
									<CorpsImportDepensesManuelles
										onSubmit={onSubmit}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										loadingBouton={loadingBouton}
										label="Sélectionnez un fichier"
									/>
									:
									(
										etape === etapeImportDepensesManuelles.validationReussi ?
											<AffichageValidationImportDepensesManuelles
												retourValidation={retourSubmit}
											/>
											:
											(
												loadingBouton ?
													<Loader size={80} sx={{ height: '70%' }}></Loader>
													:
													retourSubmit.erreurs &&
													<AffichageErreurImportDepensesManuelles
														erreurs={retourSubmit.erreurs}
														onSubmit={onSubmit}
														errorMessage={errorMessage}
														setErrorMessage={setErrorMessage}
														loadingBouton={loadingBouton}
														label="Mettre à jour le fichier"
													/>
											)
									)
							}
						</Box>
					)
			}
		</>


	)
}

export default ImportDepensesManuelles