import { Box, Grid } from '@mui/material'
import { gecinaBlueMain } from '../../../theme'
import React from 'react'
import { ImportErreurModel } from '../types/importDepensesManuellesModel'
import CorpsImportDepensesManuelles, { CorpsImportDepensesManuellesProps } from './CorpsImportDepensesManuelles'

interface AffichageErreurImportDepensesManuellesProps {
	erreurs: ImportErreurModel[],
}

type AcceptingProps = AffichageErreurImportDepensesManuellesProps & CorpsImportDepensesManuellesProps
const AffichageErreurImportDepensesManuelles: React.FC<AcceptingProps> = (
	{
		erreurs,
		errorMessage,
		setErrorMessage,
		onSubmit,
		loadingBouton,
		label
	}
) => {

	return (
		<Box sx={{ mt: '50px' }}>
			<Grid container item alignItems="center">
				<Grid container item sx={{ paddingBottom: '25px' }}>
					<Grid item xs>
						<span style={{
							fontFamily: 'BlackerDisplay',
							fontSize: 22,
							fontWeight: 900,
							color: gecinaBlueMain
						}}>
							Validation du fichier
						</span>
					</Grid>
				</Grid>
				<Grid container item>
					<Grid item xs sx={{ paddingBottom: '25px' }}>
						<span style={{
							fontFamily: 'Montserrat',
							fontSize: 16,
							fontWeight: 600,
							color: gecinaBlueMain
						}}>
							Le fichier n’est pas valide, veuillez vérifier les points de contrôle ci-dessous
						</span>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				container
				item
				sx={{
					padding: '17px 12px 17px 12px',
					border: '1px solid black',
					fontFamily: 'Montserrat',
					fontSize: 18,
					fontStyle: 'italic',
					fontWeight: 700,
					color: gecinaBlueMain
				}}
			>
				{
					erreurs.map((erreur) => {
						return (
							<Grid container item sx={{ mb: '10px' }}>
								<Grid item>
									{erreur.ligne ? `Ligne ${erreur.ligne} -` : 'Global -'}
								</Grid>
								<Grid item xs={10} sx={{ whiteSpace: 'break-spaces' }}>
									{` ${erreur.message}`}
								</Grid>
							</Grid>
						)
					})
				}
			</Grid>
			<CorpsImportDepensesManuelles
				onSubmit={onSubmit}
				errorMessage={errorMessage}
				setErrorMessage={setErrorMessage}
				loadingBouton={loadingBouton}
				label={label}
			/>
		</Box>
	)
}

export default AffichageErreurImportDepensesManuelles