import { SousTab, TabOptions } from '../../../components/tabs/TabsTypes'
import liens from '../../../enum/liens'
import { ApplicationOptionsEnum } from '../enums/ApplicationOptionsEnum'
import { AppOption } from '../types/MenuTypes'

export const TABS_ADMINISTRATION: TabOptions[] = [
	{
		label: 'Utilisateurs',
		lien: liens.utilisateurs
	}
]

export const TABS_TAXES_FONCIERES: TabOptions[] = [
	{
		label: 'Avis',
		lien: liens.avis,
		rafraichir: true
	},
	{
		label: 'Taxes',
		lien: liens.taxes
	},
	{
		label: 'Taux',
		lien: liens.taux
	}
]

export const SOUS_TABS_PARAMETRAGES: SousTab[] = [
	{
		label: 'Comptes / nomenclature de charges',
		lien: liens.nomenclature
	},
	{
		label: 'Comptes récupérables / non récupérables',
		lien: liens.comptes
	},
	{
		label: 'Matricules / taux',
		lien: liens.matricules
	},
	{
		label: 'Natures rubriques de paie',
		lien: liens.natures,
		needAdmin: true
	},
	{
		label: 'Quotes parts immeubles',
		lien: liens.quotes,
		needAdmin: true
	},
	{
		label: 'Comptes / codes sous postes',
		lien: liens.codes,
		needAdmin: true
	}
]

export const TABS_SALAIRES_GARDIENS: TabOptions[] = [
	{
		label: 'Traitements',
		lien: liens.traitements
	},
	{
		label: 'Paramétrages',
		lien: liens.parametrages,
		sousTabs: SOUS_TABS_PARAMETRAGES
	}
]

export const TABS_DEPENSES_MANUELLES: TabOptions[] = [
	{
		label: 'Importer',
		lien: liens.importer
	},
	{
		label: 'Historique',
		lien: liens.historique
	}
]

export const APP_ADMINISTRATION: AppOption = {
	label: 'Administration',
	value: ApplicationOptionsEnum.ADMINISTRATION,
	tabs: TABS_ADMINISTRATION
}

export const APP_SALAIRES_GARDIENS: AppOption = {
	label: 'Salaires gardiens',
	value: ApplicationOptionsEnum.SALAIRES_GARDIENS,
	tabs: TABS_SALAIRES_GARDIENS
}

export const APP_TAXES_FONCIERES: AppOption = {
	label: 'Taxes foncières',
	value: ApplicationOptionsEnum.TAXES_FONCIERES,
	tabs: TABS_TAXES_FONCIERES
}

export const APP_DEPENSES_MANUELLES: AppOption = {
	label: 'Dépenses manuelles',
	value: ApplicationOptionsEnum.DEPENSES_MANUELLES,
	tabs: TABS_DEPENSES_MANUELLES
}

export const APPLICATION_OPTIONS: AppOption[] = [APP_ADMINISTRATION, APP_SALAIRES_GARDIENS, APP_TAXES_FONCIERES, APP_DEPENSES_MANUELLES]