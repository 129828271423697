import React, { useContext } from 'react'
import PopinGestionFormulaire from '../../../components/form/PopinGestionFormulaire'
import { ChampsPopinModel } from '../../../components/form/types/popinGestionFormulaire'
import PopinSuppressionContenu from '../../../components/dialog/components/PopinSuppressionContenu'
import { EMPTY_FORM, FILTRES_UTILISATEURS, PROFILS_ENUM } from '../enums/filtresUtilisateursEnum'
import { UtilisateursContext } from '../context/UtilisateursContext'
import { deleteUtilisateurs, postUtilisateurs, putUtilisateurs } from '../api/utilisateursAPI'
import { UtilisateursModel } from '../types/utilisateursModel'

//Champs de la popin
const POPIN_FIELDS: ChampsPopinModel[] = [
	{
		colonnes: 1,
		champs: [
			{
				id: FILTRES_UTILISATEURS.ID,
				type: 'hidden'
			},
			{
				id: FILTRES_UTILISATEURS.NOM,
				type: 'text',
				maxLength: 50,
				label: 'Nom *'
			}
		]
	},
	{
		colonnes: 2,
		champs: [
			{
				id: FILTRES_UTILISATEURS.PRENOM,
				type: 'text',
				maxLength: 50,
				label: 'Prénom *'
			},
			{
				id: FILTRES_UTILISATEURS.EMAIL,
				type: 'text',
				maxLength: 255,
				label: 'Email *',
				disableOnUpdate: true
			}
		]
	},
	{
		colonnes: 3,
		champs: [
			{
				id: FILTRES_UTILISATEURS.ADMINISTRATION,
				type: 'checkbox',
				label: 'Administration'
			},
			{
				id: FILTRES_UTILISATEURS.TAXES_FONCIERES,
				type: 'checkbox',
				label: 'Taxes foncières'
			},
			{
				id: FILTRES_UTILISATEURS.DEPENSES_MANUELLES,
				type: 'checkbox',
				label: 'Dépenses manuelles'
			},
			{
				id: FILTRES_UTILISATEURS.SALAIRES_GARDIENS,
				type: 'checkbox',
				label: 'Salaires gardiens',
				onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
					const estCoche = e.target.checked
					const profilField = document.getElementById(`${FILTRES_UTILISATEURS.PROFIL}-container`)
					if (!profilField) return

					if (estCoche) {
						profilField.style.display = 'block'
					} else {
						profilField.style.display = 'none'
					}
				}
			}
		]
	},
	{
		colonnes: 4,
		champs: [
			{
				id: FILTRES_UTILISATEURS.PROFIL,
				type: 'select',
				label: 'Profil salaires gardiens',
				options: [{label: 'Utilisateur', value: PROFILS_ENUM.UTILISATEUR}, {label: 'Administrateur', value: PROFILS_ENUM.ADMINISTRATEUR}]
			}
		]
	}
]

const PopinGestionUtilisateurs = () => {
	const {
		ouverturePopin,
		setOuverturePopin,
		utilisateurs,
		setUtilisateurs,
		popinSuppression,
		setPopinSuppression
	} = useContext(UtilisateursContext)

	return (
		<>
			{/* Popin permettant d'ajouter / modifier un CNC */}
			<PopinGestionFormulaire
				champsPopin={POPIN_FIELDS}
				formulaireVide={EMPTY_FORM}
				donneesPopin={{
					open: ouverturePopin.open,
					donnees: ouverturePopin.utilisateurs
				}}
				onCloseDialog={(closeData) => setOuverturePopin({
					open: closeData.open,
					utilisateurs: closeData.donnees
				})}
				contenu={utilisateurs['hydra:member']}
				modificationContenu={(nouveauContenu) => setUtilisateurs({
					'hydra:totalItems': nouveauContenu.length,
					'hydra:member': nouveauContenu
				})}
				titreCreation="Ajouter un utilisateur"
				titreModification="Modifier un utilisateur"
				envoiDonnees={ouverturePopin.utilisateurs ? putUtilisateurs : postUtilisateurs}
			/>

			{/* Popin permettant de supprimer un CNC */}
			<PopinSuppressionContenu
				donneesPopin={{
					open: popinSuppression.open,
					donnees: popinSuppression.utilisateurs
				}}
				onCloseDialog={(closeData) => setPopinSuppression({
					open: closeData.open,
					utilisateurs: closeData.donnees
				})}
				titreSuppression="Suppression du paramétrage"
				texteSuppression="Confirmer la suppression du paramétrage ?"
				contenu={utilisateurs['hydra:member']}
				modificationContenu={(nouveauContenu) => setUtilisateurs({
					'hydra:totalItems': nouveauContenu.length,
					'hydra:member': nouveauContenu
				})}
				suppressionContenu={deleteUtilisateurs}
			/>
		</>
	)

}

export default PopinGestionUtilisateurs