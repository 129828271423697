enum liens {
	defaut = '/',
	login = '/auth/login',
	token = '/auth/token',
	validate = '/auth/validate',
	avis = '/avis',
	detailAvis = '/avis/detail',
	taxes = '/taxes',
	taux = '/taux',
	traitements = '/traitements',
	parametrages = '/parametrages',
	nomenclature = '/parametrages/comptes-nomenclature-de-charges',
	comptes = '/parametrages/comptes-recuperables',
	matricules = '/parametrages/matricules-taux',
	natures = '/parametrages/natures-rubriques-de-paie',
	quotes = '/parametrages/quotes-parts-immeubles',
	codes = '/parametrages/comptes-codes-sous-postes',
	nonAutorise = '/non-autorise',
	utilisateurs = '/utilisateurs',
	historique = '/historique',
	importer = '/importer',
	uuid = '/api/import/uuid',
	ajoutFichier = '/api/import/addFile',
	suppressionFichier = '/api/import/removeFile',
	validationImport = '/api/import/validateFile',
	annulerImport = '/api/import/cancel',
	sauvegarderImport = '/api/import/saveFile'
}

export default liens