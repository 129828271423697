import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { CommunApiPlatformModel, INITIAL_COMMUN_API } from '../../../types/communApiPlatformModel'
import { FiltresHistoriqueDepensesManuellesModel, HistoriqueDepensesManuellesModel, INITIAL_FILTRES_HISTORIQUE_DEP_MAN, TypeDepensesManuellesModel } from '../types/historiqueDepensesManuellesModel'
import { getListeDepensesManuelles, getTypeDepensesManuelles } from '../api/historiqueDepensesManuellesApi'
import { FILTRES_HISTORIQUE_DEP_MAN } from '../enums/filtresHistoriqueDepensesManuellesEnum'

export interface HistoriqueDepensesManuellesContextProps {
	historiqueDepenssesManuelles: CommunApiPlatformModel<HistoriqueDepensesManuellesModel[]>
	setHistoriqueDepenssesManuelles: Dispatch<SetStateAction<CommunApiPlatformModel<HistoriqueDepensesManuellesModel[]>>>
	chargementHistoriqueDepenssesManuelles: boolean
	setChargementHistoriqueDepenssesManuelles: Dispatch<SetStateAction<boolean>>
	filtresHistoriqueDepenssesManuelles: FiltresHistoriqueDepensesManuellesModel
	setFiltresHistoriqueDepenssesManuelles: Dispatch<SetStateAction<FiltresHistoriqueDepensesManuellesModel>>
	typesDepenssesManuelles: CommunApiPlatformModel<TypeDepensesManuellesModel[]>
	setTypesDepenssesManuelles: Dispatch<SetStateAction<CommunApiPlatformModel<TypeDepensesManuellesModel[]>>>
}

export const HistoriqueDepensesManuellesContext = createContext<HistoriqueDepensesManuellesContextProps>({
	historiqueDepenssesManuelles: INITIAL_COMMUN_API<HistoriqueDepensesManuellesModel[]>([]), // Partage des depenses manuelles aux éléments enfants
	setHistoriqueDepenssesManuelles: () => {
	}, // Mise à jour des depenses manuelles
	chargementHistoriqueDepenssesManuelles: true, // Détermine si les depenses manuelles sont en cours de chargement
	setChargementHistoriqueDepenssesManuelles: () => {
	}, // Modification du statut de chargement
	filtresHistoriqueDepenssesManuelles: INITIAL_FILTRES_HISTORIQUE_DEP_MAN, // Partage du filtre sur les depenses manuelles
	setFiltresHistoriqueDepenssesManuelles: () => {
	}, // Mise à jour du filtre sur les depenses manuelles
	typesDepenssesManuelles: INITIAL_COMMUN_API<TypeDepensesManuellesModel[]>([]), // Partage du filtre sur les depenses manuelles
	setTypesDepenssesManuelles: () => {
	} // Mise à jour du filtre sur les depenses manuelles
})

export const HistoriqueDepensesManuellesProvider: React.FC = (
	{
		children
	}
) => {
	const [historiqueDepenssesManuelles, setHistoriqueDepenssesManuelles] = useState<CommunApiPlatformModel<HistoriqueDepensesManuellesModel[]>>(INITIAL_COMMUN_API<HistoriqueDepensesManuellesModel[]>([]))
	const [typesDepenssesManuelles, setTypesDepenssesManuelles] = useState<CommunApiPlatformModel<TypeDepensesManuellesModel[]>>(INITIAL_COMMUN_API<TypeDepensesManuellesModel[]>([]))
	const [chargementHistoriqueDepenssesManuelles, setChargementHistoriqueDepenssesManuelles] = useState(true)
	const [filtresHistoriqueDepenssesManuelles, setFiltresHistoriqueDepenssesManuelles] = useState<FiltresHistoriqueDepensesManuellesModel>(INITIAL_FILTRES_HISTORIQUE_DEP_MAN)

	useEffect(
		() => {
			getTypeDepensesManuelles()
				.then((response) => {
					setTypesDepenssesManuelles(response)
				})
			getListeDepensesManuelles(filtresHistoriqueDepenssesManuelles[FILTRES_HISTORIQUE_DEP_MAN.PAGE])
				.then((response) => {
					setHistoriqueDepenssesManuelles(response)
					setChargementHistoriqueDepenssesManuelles(false)
				})
		}, []
	)

	return <HistoriqueDepensesManuellesContext.Provider
		value={{
			historiqueDepenssesManuelles,
			setHistoriqueDepenssesManuelles,
			chargementHistoriqueDepenssesManuelles,
			setChargementHistoriqueDepenssesManuelles,
			filtresHistoriqueDepenssesManuelles,
			setFiltresHistoriqueDepenssesManuelles,
			typesDepenssesManuelles,
			setTypesDepenssesManuelles
		}}
	>
		{children}
	</HistoriqueDepensesManuellesContext.Provider>
}