import React, { useContext } from 'react'
import { HistoriqueDepensesManuellesContext } from '../context/HistoriqueDepensesManuellesContext'
import { FILTRES_HISTORIQUE_DEP_MAN } from '../enums/filtresHistoriqueDepensesManuellesEnum'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { FiltresHistoriqueDepensesManuellesModel } from '../types/historiqueDepensesManuellesModel'
import { getListeDepensesManuelles } from '../api/historiqueDepensesManuellesApi'
import { Grid, MenuItem } from '@mui/material'
import TextField from '../../../components/fields/FormTextField'
import { fontFamilyMonserrat, gecinaBrownMain, WHITE } from '../../../theme'
import Button from '../../../components/button/Button'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { FILTRES_TRAITEMENT_SG_NF } from '../../TraitementSGNF/enums/filtresTraitementSGNFEnum'
import { typeFichierTraitementSGNFEnum } from '../../TraitementSGNF/enums/fichierTraitementSGNFEnum'
import FormSelectField from '../../../components/fields/FormSelectField'

const INITIAL_VALEURS_FORMULAIRE = {
	[FILTRES_HISTORIQUE_DEP_MAN.ID]: null,
	[FILTRES_HISTORIQUE_DEP_MAN.FICHIER_INTEGRE]: '',
	[FILTRES_HISTORIQUE_DEP_MAN.TYPE]: 'Tous'
}

const FiltresHistoriqueDepensesManuelles = () => {
	const {
		setChargementHistoriqueDepenssesManuelles,
		setHistoriqueDepenssesManuelles,
		filtresHistoriqueDepenssesManuelles,
		setFiltresHistoriqueDepenssesManuelles,
		typesDepenssesManuelles
	} = useContext(HistoriqueDepensesManuellesContext)

	const methods = useForm<FiltresHistoriqueDepensesManuellesModel>({
		defaultValues: INITIAL_VALEURS_FORMULAIRE
	})
	const { register, handleSubmit, reset } = methods

	const onSubmit: SubmitHandler<FiltresHistoriqueDepensesManuellesModel> = (values) => {
		setChargementHistoriqueDepenssesManuelles(true)
		const data = {
			...filtresHistoriqueDepenssesManuelles,
			[FILTRES_HISTORIQUE_DEP_MAN.PAGE]: 1
		}
		setFiltresHistoriqueDepenssesManuelles(data)

		// Récupération de la liste des depenses manuelles selon les valeurs du filtre
		return getListeDepensesManuelles(1, values.fichier_integre, values.type ?? undefined)
			.then((response) => {
				setHistoriqueDepenssesManuelles(response)
				setChargementHistoriqueDepenssesManuelles(false)
			})
	}

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
				<Grid container>
					<Grid container item spacing={2}>
						<Grid item xs={3}>
							<TextField
								register={register(FILTRES_HISTORIQUE_DEP_MAN.FICHIER_INTEGRE)}
								id={FILTRES_HISTORIQUE_DEP_MAN.FICHIER_INTEGRE}
								label="Fichier"
								placeholder="Saisir une partie du nom"
							/>
						</Grid>
						<Grid item xs={3}>
							<FormSelectField
								register={register(FILTRES_HISTORIQUE_DEP_MAN.TYPE)}
								id={FILTRES_HISTORIQUE_DEP_MAN.TYPE}
								label="Type"
								placeholder="Saisir une partie du type"
								defaultValue="Tous"
							>
								<MenuItem value="Tous" key="Tous">
									Tous
								</MenuItem>
								{
									typesDepenssesManuelles['hydra:member'].map(
										(type) => (
											<MenuItem value={type.libelle} key={type.libelle}>
												{type.libelle}
											</MenuItem>
										)
									)
								}
							</FormSelectField>
						</Grid>
						<Grid container item xs={2} spacing={2} sx={{ m: 0 }} alignContent="flex-start">
							<span
								style={{
									width: '100%',
									paddingRight: 15,
									paddingTop: 7,
									fontSize: 14,
									marginLeft: 0,
									boxSizing: 'border-box',
									textAlign: 'left',
									fontFamily: fontFamilyMonserrat,
									fontWeight: 500
								}}
							> </span>

							<Grid item xs={7} sx={{ pt: '0px !important', height: 'fit-content' }}>
								<Button
									sx={{
										height: '32px',
										p: 0,
										minWidth: '100%',
										fontWeight: 600
									}}
									bgcolor={gecinaBrownMain}
									color={WHITE}
									border={false}
									disabled={false}
									type={buttonTypesEnum.submit}
								>
									Filtrer
								</Button>
							</Grid>

							<Grid item xs={3} md={2} sx={{ pt: '0px !important', height: 'fit-content' }}>
								<Button
									sx={{
										height: 32,
										p: 0,
										minWidth: '100%'
									}}
									bgcolor={gecinaBrownMain}
									color={WHITE}
									border={false}
									disabled={false}
									type={buttonTypesEnum.submit}
									onClick={() => reset(INITIAL_VALEURS_FORMULAIRE)}
								>
									<AutorenewIcon />
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</FormProvider>
	)

}

export default FiltresHistoriqueDepensesManuelles