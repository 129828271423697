import { gecinaBlueMain } from '../../../theme'
import { Box, Grid } from '@mui/material'
import React from 'react'

const EnteteImportDepensesManuelles = () => {
	return (
		<Box sx={{ background: gecinaBlueMain, minHeight: 107}}>
			<Grid container item xs={12} justifyContent="space-between" sx={{ pt: 5 , paddingX: '172px' }}>
				<Box sx={{ fontFamily: 'BlackerDisplay', fontSize: 28, fontWeight: 900 }}>
					Importer une dépense
				</Box>
			</Grid>
		</Box>
	)
}

export default EnteteImportDepensesManuelles